
@import 'bulma/bulma.sass'

@import '@carbon/charts/styles'
@import '@carbon/type/scss/classes'
// @import '@carbon/elements/scss/grid';
@import '@carbon/colors/scss/colors'
@import '@carbon/elements/scss/colors'
@import '@carbon/elements/scss/type/type'
@import '@carbon/elements/scss/type/styles'
@import '@carbon/elements/scss/type/font-face/mono'
@import '@carbon/elements/scss/type/font-face/sans'

// @include carbon--type-reset()
// @include carbon--font-face-mono()
// @include carbon--font-face-sans()

@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Literata)

$font-roboto: 'Roboto'

$widescreen-enabled: false
$fullhd-enabled: false
$tablet-enabled: false
$touch: false

$color-dark: #4a4a4a
$color-light: #e4e4e4

$body-line-height: 1.5
$body-size: 10px !default
$body-font-size: .75em !default

#map 
    height: 180px
#brand-loading
    background-position: center
    background-repeat: no-repeat
    height: 65%
    width: 100%
#brand-loading.xs
    background-image: url('/assets/archfndr_logo_loading_xs.gif')
#brand-loading.sml
    background-image: url('/assets/archfndr_logo_loading_sml.gif')
#brand-loading.med
    background-image: url('/assets/archfndr_logo_loading_med.gif')
#brand-loading.lrg
    background-image: url('/assets/archfndr_logo_loading_lrg.gif')
body
    margin: 0px 0px 0px 0px
    min-height: 1500px
    font-family: $font-roboto
    font-size: $body-font-size
    line-height: $body-line-height
video
    width: 100%
.page
    margin-bottom: 50px

.page-top
    width: 100%
    margin-right: 20px
    float: left

.columns
    margin: 0px 0px
    margin-left: 0px
    margin-right: 0px
    margin-bottom: 0px
    margin-top: 0px
.columns:last-child
    margin-bottom: 0
.content 
    ul
        list-style: none
        list-style-type: none
    li + li
        margin-top: 0
.content-main
    min-height: 1200px
    @include until($tablet) 
        margin-top: 100px
        min-height: 1000px
        //padding: 0px 20px 0px 20px
        overflow: hidden
.content-main.section
    padding-top: 25px
.content-billboard
    .elA
        background-color: #eeeeee
        height: auto
        aspect-ratio: 1/1
    .elB
        height: auto
        aspect-ratio: 4/3
        background-color: blue
.toolbar-section
    font-size: 1rem
    font-family: Helvetica, Arial, sans-serif
    .breadcrumb a
        color: #9a9a9a
    .breadcrumb a:hover
        color: #4a4a4a
    .breadcrumb
        font-size: .75rem
.section-billboard 
    margin: 0px 0px 50px 0px
    min-height: 300px

.site-footer
    background-color: black
    min-height: 300px
    li
        color: white
.media-billboard-desc
    line-height: 2rem
.module
    margin-bottom: 50px
.B1
    background-color: $carbon--blue-10
.B2
    background-color: $carbon--blue-30
.B3
    background-color: $carbon--blue-70
.B4
    background-color: $carbon--blue-100

.ar11
    aspect-ratio: 1/1
.ar21
    aspect-ratio: 2/1
.ar31
    aspect-ratio: 3/1
.ar32
    aspect-ratio: 3/2
.ar43
    aspect-ratio: 4/3
.ar53
    aspect-ratio: 5/3
.ar169
    aspect-ration: 16/9

.layout-img
    min-height: 100px

.layout-sect
    min-height: 500px
h2, h3, h4, h5
    font-family: GoodOT, Helvetica, Arial, sans-serif

// .layout-section
//     li
//         height: auto
//         aspect-ratio: 3/1
//         background-color: $carbon--blue-70
//         margin-bottom: 2rem

.layout-top
    margin-bottom: 5rem
.layout-related
    margin-top: 7.5rem
.layout-bottom
    h6
        font-size: .9375rem
    article
        height: 124px
    dt
        margin: 0rem 0rem 2rem 0rem
    dd
        display: block
        margin-bottom: 10px
    .content
        height: auto
.spacer
    height: 100px
    // margin-bottom: 500px

.topic-list
    margin-left: 0px
    li
        font-size: .75rem
        display: inline-block
        float: left
        padding: .25rem

    li:hover
        background-color: #9a9a9a
.header-arrows
    display: none
    height: 0px

.snippet
    margin-bottom: 1.5rem
    a
        color: black
    a:hover
        text-decoration: underline
.snippet-news
    margin-bottom: 2rem
    .snippet-news-meta
        font-size: .5rem
        margin-bottom: .25rem
    .snippet-news-title
        font-size: 1rem
        line-height: 1.25rem
        margin-bottom: .25rem
        color: black
    .snippet-news-tags
        font-size: .5rem
        margin-bottom: .125rem
    .snippet-news-desc
        font-size: .75rem
        line-height: 1.25rem
.snippet-video
    background-color: black

// .snippet-video-media
//     background-color: #777777

.content
  width: 100%

.content.is-vcentered 
  display: flex
  flex-wrap: wrap
  align-content: center
  align-items: center


.content.fixed
    height: 150px

.video-strip
    width: 100%
    display: inline
    float: left
    li
        display: inline-block
        width: 25%

.section-block
    margin-bottom: 5rem

/////////////////
/// LECTURES ////
/////////////////
.lecture
    min-height: 100px
.lecture.billboard
    p
        margin-bottom: 3rem
.lecture .stats
    div
        text-align: center
    h6
        font-size: 3.5rem
        text-align: center
.lecture.stats
    margin-bottom: 5rem

.lecture.table
    tr, td, th
        font-size: .75rem

.lecture-head.columns
    border-bottom: 1rem
    border-bottom-color: black
.lecture-host-list
    font-size: .7rem
    margin-left: 4rem
    ul
        margin-top: 1rem
    li
        overflow: hidden
        clear: both
        color: #929292
        padding: .15rem
        margin: 0rem 0rem .5rem 0rem
    li:hover
        color: #222
        

.lectures-content
    clear: both
.lecture-head .lecture
    text-align: center
.lecture-head p
    font-size: 1.35rem
    text-align: left
    font-weight: 100
    font-family: 'IBM Plex Sans Condensed', 'Helvetica Neue', Arial, sans-serif

.listing-left-bar
    margin-bottom: 4rem
.lecture.people-guest-list
    li
        float: left
        font-size: .65rem
        color: #929292
        padding-right: .25rem
    li:hover 
        color: #343434

.links-list
.links-item
    margin-bottom: 3rem
    p
        font-size: 1.15rem
        margin-bottom: 1rem
    a
        color: black
    a:hover
        text-decoration: underline
.links-item.date
    li
        float: left
        display: inline
.links-content
    iframe
        aspect-ratio: 5/3
.links-ref
    font-size: 1rem


.listing-navbar
    margin-bottom: 2rem
    nav
        margin-bottom: .75rem
        overflow: hidden
    .listing-search
        clear: both
        .search-btn
            border: none
            margin: 0rem .75rem 0rem .75rem
        .search-btn:hover
            background-color: $color-light
    li
        cursor: pointer
        display: inline-block
        float: left
        padding: .25em
        margin: .125em
    .is-active
        background-color: #E4E4E4
    .is-active:hover
        background-color: #E4E4E4
    .is-off:hover
        background-color: #8d8d8d
.xxx
    li
        display: block

.guest-container
    min-height: 100px

/////////////////
//// PEOPLE /////
/////////////////
.people-lecture-list
    text-align: align-center
    justify-content: center
    li
        width: 50%
        min-height: 300px
        line-height: 1
        // float: left
        display: inline-block

        // @include until($tablet)
        //     width: 100%
    .list-item
        overflow: hidden
        .video
            background-color: black
        .video-desc
            min-height: 150px
.people-list.visited
    margin-bottom: 1rem    
.people-list.listing
    min-height: 600px
.people-list
    overflow: hidden
    clear: both
    margin-top: 1em
    .selected
        color: hsl(0, 0%, 70%)
    h6
        float: left
        padding: .25em
        margin: .125em
    li
        //@include carbon--type-style(label-02)
        display: inline-block
        float: left
        padding: .25em
        margin: .125em
    li:hover
        background-color: #E4E4E4
    a
        color: #343434
        cursor: pointer
    // a:hover
    //     color: #E4e4e4
    //     li
    //         background-color: #8d8d8d
.store-speakers
    margin-top: 3em
    // overflow: hidden
    ul
        text-align:  center
    ul li 
        display: inline-block
        float: left
        font-size: 10px
        padding: 0px 2px

.leaflet-control-zoom
    visibility: hidden
.media-list
    line-height: 0
    li
        display: inline-block
        background: hsl(0, 0%, 90%)
        width: 25px
        height: 25px
        margin: 1px
        transition: all .25s ease-out
        a
            width: 100%
            height: 100%
            display: block
            color: hsl(0, 0%, 90%)
        a:hover
            color: hsl(0, 0%, 50%)
    li:hover
        background: hsl(0, 0%, 50%)
        cursor: pointer

.showItem
    top: 0
    left: 0
    height: 500px
    width: 500px
    // z-index: 10
    background: purple
    position: absolute

.video-embed
    //background-color: black
    // min-height: 190px
    width: 100%
    //aspect-ratio: 2/1
    iframe
        width: 100%
        min-height: 100%
// #sub-nav
//     @include until($desktop)
//         clear: both
.schools-stats
    clear: both

.schools-key
    margin-bottom: 2rem

.schools-key-item
    clear: both
    div:first-child
        width: 15%
        height: 25px
        display: block        
        float: left
    .active
        background-color: #e63599
    .inactive
        background-color: hsl(0, 0%, 30%)

.school-list
    .active
        background-color: #e63599
        color: white
    .passive
        color: hsl(0, 0%, 70%)
    line-height: 1
    @include until($desktop) 
        li
            width: 100%
            display: block
            color: green
    +desktop
        li
            display: inline-block
            float: left
            width: auto
            // height: 200px
            background-color: hsl(0, 0%, 30%)
            a
                color: hsl(0, 0%, 70%)
    li:hover
        background-color: hsl(0, 0%, 70%)
        a
            color: hsl(0, 0%, 30%)



.top-nav--wrap
    display: block
    overflow: hidden
    height: 60px
    background-color: black
    @include until($tablet)
        height: 120px
        width: 100%
        position: fixed
        top: 0px
        z-index: 5
        background-color: white    

.top-nav--nav
    height: 60px
    padding: 5px 0px 5px 0px
    @include until($tablet) 
        text-align: center
        height: 160px
    #brand-logo
        display: block
        float: left
        padding: 5px 0px 0px 0px   
        @include until($tablet) 
            display: flex
            float: none
            align-content: center
            text-align: center
    #brand-img
        background-position: center
        background-repeat: no-repeat
        background-image: url('/assets/archfndr_logo_white_sml.svg')
        height: 42px
        width: 157px
        @include until($tablet) 
            background-image: url('/assets/archfndr_logo_black_sml.svg')
    .column
        padding: 0px
    ul.navbar-end
        @include until($tablet) 
            visibility: hidden
        float: right
        height: 50px
        // padding: 0px 10px 0px 0px
        li
            display: inline-block
            margin-left: 15px
            clear: none
            div
                width: 100%
                height: 55px
                border-bottom-style: solid
                border-bottom-width: 5px
                border-bottom-color: black
                padding-top: 13px
        a
            color: hsl(0, 0%, 70%)
            border-bottom-color: black
        a:hover
            color: #F4f4f4
        a.is-active
            border-bottom-color: white
            color: white
            text-decoration: none
            div
                border-bottom-color: white

.top-nav--mobile
    visibility: hidden
    height: 0px
    @include until($tablet) 
        visibility: visible
        padding: 5px 20px 0px 20px
        ul.mobile-nav-items
            height: 35px
            background-color: #343434
            text-align: center
            li
                display: inline-block
                padding: 6px 10px 0pc 5px
                font-weight: bold
                font-size: .875em
            a
                color: #8d8d8d

            a.is-active
                color: #F4f4f4

.hide
    opacity: 0
    transition: opacity .5s  
.show
    opacity: 1
    transition: opacity .5s

// LARGE & UP
input[type="checkbox"]:focus
    outline: 0

input[type="checkbox"]:hover
    outline: 0

.my_number
    @include carbon--type-scale(9)

// CARBON CHART STYLING
.pie-label
    visibility: hidden
// CARBON CHART STYLING
.pie-label
    visibility: hidden
// .chart-holder.bx--chart-holder
//     margin-top: -28px

.chart-alluvial
    svg.bx--cc--alluvial
        height: 115%

// .layout-svg-wrapper
//     height: 150%
// .chart-holder
//     margin-top: -250px

// .layout-child > .legend 
//     visibility: hidden // hide school name links from chart
    // margin-bottom: 500px
// .chart-holder
//     margin-top: -250px

// .layout-child > .legend 
//     visibility: hidden // hide school name links from chart
    // margin-bottom: 500px
@media screen and (max-width: 768px)
    .top-nav--wrap
        background-color: purple